.main {
    top: 55%;
    left: 60%;
    display: flex;
    transform: translate(-50%, -50%);
    position: absolute;
  
  } 
  
  .main div {
    margin: 10px;
    width: 50px;
    height: 50px;
    background-color: rgb(102, 71, 191);
    border-radius: 50%;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
  }
  
  .one {
    animation-name: load-one;
  }
  
  @keyframes load-one {
    30% {
       transform: translateY(-50%);
    }
  }
  
  .two {
    animation-name: load-two;
  }
  
  @keyframes load-two {
    50% {
       transform: translateY(-50%);
    }
  }
  
  .three {
    animation-name: load-three;
  }
  
  @keyframes load-three {
    70% {
       transform: translateY(-50%);
    }
  }